
import { defineComponent, watch, watchEffect, ref, computed, onMounted } from 'vue'
import ItemTreeIndex from './ItemTreeIndex.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { markTableOfContentsByCurrent, markTableOfContentsUpToRoot } from '@/domain/item/itemViewer/itemIndex'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'

export default defineComponent({
  components: {
    ItemTreeIndex,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    viewerType: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()

    const pid = computed(() => route.params.pid)

    onMounted(() => {
      store.dispatch('indexSearch', pid.value)
    })

    watch(pid, () => {
      // ブラウザバックした際は検索しない
      if (!pid.value) return
      store.dispatch('indexSearch', pid.value)
    })

    const contentTreeIndex = ref()

    const getTreeKey = (sortTemporal: string | undefined) => {
      if (!sortTemporal || !parseInt(sortTemporal)) { // unknown
        return '0000〜0000'
      }
      return sortTemporal
    }

    watchEffect(() => {
      const key = getTreeKey(props.item.sindex?.sortTemporal)
      const indexResponse = store.getters.indexResponse
      const markedIndexResponse = markTableOfContentsUpToRoot(markTableOfContentsByCurrent(key, indexResponse))
      contentTreeIndex.value = markedIndexResponse
    })

    const metaArrayProcessing = (meta: Array<string>) => {
      return meta ? meta.join() : ''
    }

    const makeRootUrl = ({ pid, root }: {pid: string, root: string}) => pid ? makeUpdatedPidString(pid) : `pid/${root}`

    return {
      contentTreeIndex,
      metaArrayProcessing,
      makeRootUrl,
      makeUpdatedPidString,
    }
  },
})
